<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="formData" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="交班人" prop="shiftman" style="width: 15%">
              <a-input v-model.trim="formData.shiftman" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="接班人" prop="takeoverman" style="width: 15%">
              <a-input v-model.trim="formData.takeoverman" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 12%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button type="primary" @click="deleteBatch()">删除</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500, y: 600 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="shifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="takeovertime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="nextshifttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="shiftstatus" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'完成':'未完成'}}</a-tag>
          </span>
          <span slot="shiftnum" slot-scope="value,record">
            <span>{{patrolShifts[record.shiftnum-1].value+(record.takeovernum?"->"+patrolShifts[record.takeovernum-1].value:'')}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button @click="showModal(value,record)" type="link">详情</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="shiftVisible" title="交接班历史" class="mymodal" :dialogStyle="dialogStyle">
      <template slot="footer">
        <a-button @click="shiftVisible=false">取消</a-button>
      </template>
      <a-divider style="font-weight: bold;margin-bottom: 30px;margin-top: -10px">器材交接</a-divider>
      <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px">
        <a-row>
          <a-col :span="12">器材清单</a-col>
          <a-col :span="12">器材确认情况</a-col>
        </a-row>
        <a-row>
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equiplist" :disabled="true"></textarea></a-col>
          <a-col :span="12"><textarea style="width: 200px;" cols="26" rows="2" v-model="queryParams.equipconfirm" :disabled="true"></textarea></a-col>
        </a-row>
      </div>
      <a-divider style="margin-top: 25px;font-weight: bold;margin-bottom: 30px;margin-top: 3px" >设备系统状况</a-divider>
      <div style="padding: 20px 10px 10px 25px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -28px">
        <a-row>
          <a-col :span="24">设备系统状况</a-col>
        </a-row>
        <a-row>
          <a-col :span="24"><textarea style="width: 412px;" cols="60" rows="3" v-model="queryParams.devicesituation" :disabled="true"></textarea></a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">跟进及处理情况</a-col>
        </a-row>
        <a-row>
          <a-col :span="24"><textarea style="width: 412px;" cols="60" rows="3" v-model="queryParams.description" :disabled="true"></textarea></a-col>
        </a-row>
      </div>
      <div style="border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: 10px;">
        <a-divider style="font-weight: bold;margin-top: 5px">交班说明</a-divider>
        <div style="padding: 0px 10px 10px 25px;">
          <textarea style="width: 412px;" cols="60" rows="2" v-model="queryParams.shiftdesc" :disabled="true"></textarea>
          <a-divider style="font-weight: bold;margin-top: 3px">交接班状态</a-divider>
          <a-row>
            <a-col :span="2">状态</a-col>
            <a-col :span="2">班次</a-col>
            <a-col :span="8">时间</a-col>
            <a-col :span="12">姓名</a-col>
<!--            <a-col :span="5">账号</a-col>-->
          </a-row>
          <a-row>
            <a-col :span="2"><span>{{'交班'}}</span></a-col>
            <a-col :span="2"><span>{{queryParams.shiftnum}}</span></a-col>
            <a-col :span="8"><span>{{queryParams.shifttime}}</span></a-col>
            <a-col :span="12"><span>{{queryParams.shiftman}}</span></a-col>
<!--            <a-col :span="5"><span>{{queryParams.shiftaccount}}</span></a-col>-->
          </a-row>
          <a-row>
            <a-col :span="2"><span>{{'接班'}}</span></a-col>
            <a-col :span="2"><span>{{queryParams.takeovernum}}</span></a-col>
            <a-col :span="8"><span>{{queryParams.takeovertime}}</span></a-col>
            <a-col :span="12"><span>{{queryParams.takeoverman}}</span></a-col>
<!--            <a-col :span="5"><span>{{queryParams.takeoveraccount}}</span></a-col>-->
          </a-row>
        </div>
      </div>
    </a-modal>
  </page-container>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey, getTimeRange} from "U/index";
import {getEngineerShiftDataListByCondition} from "A/patrol";
import {patrolShifts} from "@/json/wlwhistory";
import deptselect from "@/mixins/deptselect";

export default {
  name: "engineerShiftHistory",
  mixins: [deptselect,pagination],
  data() {
    return {
      moment,
      patrolShifts,
      shiftVisible:false,
      dialogStyle:{
        top:"10px"
      },
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      userdepidCascaderSelected: [],
      eventOptions:[],
      shiftStatusList:[
        {"value":"未完成"},
        {"value":"完成"},
      ],
      formData:{
        monitorpointname:'',
        shiftman:'',
        takeoverman:'',
        starttime:'',
        endtime:'',
        monitorpointnum:'',
      },
      queryParams:{
        monitorpointname:'',
        shiftman:'',
        takeoverman:'',
        shiftstatus:'',
        starttime:'',
        endtime:'',
        monitorpointnum:'',
        equiplist:'',
        shiftnum:null,
        takeovernum:null,
        equipconfirm:'齐备',
        shifttime:'',
        takeovertime:'',
        description:'',
        shiftaccount:'',
        takeoveraccount:'',
        shiftdesc:'',
        devicesituation:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '班次',
          dataIndex: 'shiftnum',
          key: 'shiftnum',
          ellipsis: true,
          scopedSlots: { customRender: 'shiftnum' }
        },
        {
          title: '交班时间',
          dataIndex: 'shifttime',
          key: 'shifttime',
          ellipsis: true,
          scopedSlots: { customRender: 'shifttime' }
        },
        {
          title: '接班时间',
          dataIndex: 'takeovertime',
          key: 'takeovertime',
          ellipsis: true,
          scopedSlots: { customRender: 'takeovertime' }
        },
        // {
        //   title: '交班时间',
        //   dataIndex: 'nextshifttime',
        //   key: 'nextshifttime',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'nextshifttime' }
        // },
        {
          title: '交班人',
          dataIndex: 'shiftman',
          key: 'shiftman',
          ellipsis: true,
        },
        // {
        //   title: '交班人账号',
        //   dataIndex: 'shiftaccount',
        //   key: 'shiftaccount',
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '接班人',
          dataIndex: 'takeoverman',
          key: 'takeoverman',
          ellipsis: true,
        },
        // {
        //   title: '接班人账号',
        //   dataIndex: 'takeoveraccount',
        //   key: 'takeoveraccount',
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '交接班状态',
          dataIndex: 'shiftstatus',
          key: 'shiftstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'shiftstatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          align:'center',
          width:70,
          ellipsis: true,
          scopedSlots: { customRender: 'operation' }
        },
      ],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "工程交接班历史"
    },
    breadcrumb() {
      const pages = [{name:"工作查阅与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.formData.starttime = start;
      this.formData.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formData.userdepsid = val[val.length-1];
      }else {
        this.formData.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.formData,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEngineerShiftDataListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.eventcode='';
      this.$refs.queryForm.resetFields();
      this.userdepidCascaderSelected=[]
    },
    showModal(value,record){
      this.shiftVisible=true
      let params={
        monitorpointnum:record.monitorpointnum,
        shifttime: record.shifttime,
        shiftid:record.shiftid,
      }
      getEngineerShiftDataListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            this.queryParams.shiftstatus=res.item[0].shiftstatus
            this.queryParams.equiplist=res.item[0].equiplist
            this.queryParams.equipconfirm=res.item[0].equipconfirm
            this.queryParams.shifttime=res.item[0].shifttime
            this.queryParams.description=res.item[0].description
            this.queryParams.shiftaccount=res.item[0].shiftaccount
            this.queryParams.shiftman=res.item[0].shiftman
            this.queryParams.shiftnum=res.item[0].shiftnum?patrolShifts[res.item[0].shiftnum-1].value:null
            this.queryParams.takeovernum=res.item[0].takeovernum?patrolShifts[res.item[0].takeovernum-1].value:null
            this.queryParams.shifttime=res.item[0].shifttime?moment(res.item[0].shifttime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
            this.isShift=false
            this.queryParams.shiftdesc=res.item[0].shiftdesc
            this.queryParams.takeoveraccount=res.item[0].takeoveraccount
            this.queryParams.takeoverman=res.item[0].takeoverman
            this.queryParams.takeovertime=res.item[0].takeovertime?moment(res.item[0].takeovertime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"):null
            this.queryParams.devicesituation=res.item[0].devicesituation
          }
        }
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let shiftid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'shiftid', shiftid);

    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{
      });
    },
  },
}
</script>

<style scoped>

</style>